export const DEFAULT_DOCUMENT_FILTERS = {
  page: 1,
  pageSize: 50,
  sortBy: 'createdAt',
  sortOrder: 'asc',
  offset: 0,
};

export const DOCUMENT_TYPES = {
  folders: true,
  documents: false,
};
