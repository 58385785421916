import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentContext } from './context/useDocumentContext';
import routes from '../config/routes';
import { getTransformedUrl } from '../utils/utils';

interface NavItem {
  title: string;
  href: string | null;
}

const useBreadcrumb = () => {
  const { otherDetails, documents: data } = useDocumentContext();
  const [breadcrumb, setBreadcrumb] = useState<NavItem[]>([]);
  const parentId = useParams().folderId || null;

  // useEffect(() => {
  //   const path = location.pathname;
  //   const routes = path.split('/').filter(route => route !== '');

  //   const breadcrumbRoutes: NavItem[] = [];

  //   routes.forEach((route, index) => {
  //     let title = route[0].toUpperCase() + route.slice(1);

  //     // Handle the "groups" route
  //     if (route === 'groups') {
  //       title = 'Group Listing';
  //       breadcrumbRoutes.push({
  //         title: title,
  //         href: `/${routes.slice(0, index + 1).join('/')}`,
  //       });

  //       // If there is an ID following "groups", add "Documents" instead of the ID
  //       if (routes[index + 1]) {
  //         breadcrumbRoutes.push({
  //           title: groupName || 'Group Name',
  //           href: `/groups/${routes[index + 1]}`,
  //         });
  //         // Skip adding the ID to the breadcrumb
  //       }

  //       if (routes[index + 3]) {
  //         breadcrumbRoutes.push({
  //           title: folderName,
  //           href: `/groups/${routes[index + 1]}/folders/${routes[index + 3]}`,
  //         });
  //         // Skip adding the ID to the breadcrumb
  //         return;
  //       }
  //     }
  //   });

  //   // Add "Home" as the first breadcrumb item
  //   setBreadcrumb([{ title: 'Home', href: '/' }, ...breadcrumbRoutes]);
  // }, [location.pathname]);

  useEffect(() => {
    if (!otherDetails) return;
    const breadcrumbs = [];
    if (otherDetails.group?.name) {
      breadcrumbs.push({
        title: otherDetails.group.name,
        href: getTransformedUrl(routes.groupDocuments, {
          groupId: otherDetails.group.id,
        }),
        // Not clickable on detail page
      });
    }
    // Add breadcrumb items from otherDetails.breadcrumb array
    if (Array.isArray(otherDetails.breadcrumb)) {
      otherDetails.breadcrumb.forEach((item: any, index: number) => {
        breadcrumbs.push({
          title: item.name,
          href:
            index === otherDetails.breadcrumb.length - 1
              ? null // Last item (self) is not clickable
              : getTransformedUrl(routes.groupFoldersDocuments, {
                  groupId: otherDetails.group.id,
                  folderId: item.id,
                }),
        });
      });
    }

    setBreadcrumb([
      {
        title: 'Groups',
        href: getTransformedUrl(routes.allGroups, { groupType: 'all' }),
      },
      ...breadcrumbs,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherDetails]);

  return { breadcrumb };
};

export default useBreadcrumb;
