/* eslint-disable @typescript-eslint/no-unused-vars */
import APIs from '../../config/apis';
import { useState } from 'react';
import apiClient, { BASE_URL } from '../../config/axiosConfig';
import { Document, DocumentVersion } from '../../contexts/DocumentContext';
import { useDocumentContext } from '../context/useDocumentContext';
import { useGroupContext } from '../context/useGroupContext';
import { useAuthContext } from '../context/useAuthContext';
import { getDocumentViewUrl, getUserFullName } from '../../utils/utils';
import { showToast } from 'design-web';
import { PermissionTypes } from '../../types/types';
import { User } from '../../contexts/UserContext';

export interface SharedUser extends User {
  permission?: PermissionTypes;
  userId: string;
}

const useDocumentService = () => {
  const [data, setData] = useState<Document[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { setOtherDetails, setDocument, setDocumentVersion } =
    useDocumentContext();
  const { updateGroupType, setGroupType } = useGroupContext();
  const { userInfo } = useAuthContext();

  // Function to check file names
  const checkFileNames = async (
    fileNames: string[],
    groupId: string,
    parentId?: string
  ): Promise<any> => {
    try {
      if (!fileNames || !groupId) {
        setError('File names and groupId are required');
        return null;
      }

      setLoading(true);

      // API call to check the validity of file names
      const response = await apiClient({
        ...APIs.DOCUMENT.CHECK_FILE_NAMES,
        data: { fileNames, groupId, parentId },
      });
      const validFileNames = response.data;

      if (response.status === 200) {
        return validFileNames;
      }

      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return null;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const createDocument = async (
    documentData: any
  ): Promise<Document | null> => {
    try {
      if (!documentData) {
        setError('No document data provided');
        return null;
      }
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.CREATE,
        data: documentData,
      });
      const documentRes = response.data;
      if (response.status === 200) {
        setData([...data, documentRes]);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const updateDocument = async (
    documentId: string,
    documentData: any
  ): Promise<Document | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.PATCH(documentId),
        data: documentData,
      });

      const documentRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      console.log(data, 'data');
      const result = [
        ...data.map(item =>
          item.id === documentRes.id ? { ...item, ...documentRes } : item
        ),
      ];
      console.log(result, 'result');
      setData(result);

      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteDocument = async (id: string): Promise<Document | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.DELETE(id),
      });
      const documentRes = response.data;
      if (response.status === 200) {
        setData(data.filter(item => item.id !== id));
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteDocumentVersion = async (
    id: string,
    vid: string
  ): Promise<Document | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.DELETE_VERSION(id, vid),
      });
      const documentRes = response.data;

      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getDocumentList = async (
    groupId: string,
    parentId: string | null,
    queryPayload: any = { searchBy: '' }
  ): Promise<Document[] | null> => {
    try {
      setLoading(true);
      // check if it's group document or a folder documents
      let documentListEndPoint = APIs.GROUP.GET_ALL_DOCUMENTS(groupId);
      if (parentId) {
        documentListEndPoint = APIs.DOCUMENT.GET_ALL(parentId);
      }

      console.log('API Endpoint:', documentListEndPoint.url); // Debug: Verify endpoint URL
      console.log('Query Payload Sent:', queryPayload);
      const response = await apiClient({
        ...documentListEndPoint,
        params: queryPayload,
      });

      // add DocumentShare data to childern if it's not present, from it's parent
      if (
        response.data?.data?.children &&
        response.data?.data?.DocumentShare?.length
      ) {
        response.data?.data?.children.map((doc: any) => {
          doc.DocumentShare = doc.DocumentShare?.length
            ? doc.DocumentShare
            : response.data?.data?.DocumentShare;
        });
      }

      const documentRes =
        response.data?.data?.documents || response.data?.data?.children;
      const breadcrumbRes = response.data?.data?.breadcrumb || [];
      setGroupType('own');
      setOtherDetails({ group: response.data?.data?.group });
      if (response.data?.data?.documents) {
        const otherDetails = {
          ...(response.data?.data?.documents[0] || []),
          group: response.data?.data?.group,
          breadcrumb: breadcrumbRes,
        };
        setOtherDetails(otherDetails);
        updateGroupType(otherDetails);
      } else if (response.data?.data?.children) {
        setOtherDetails({ ...response.data?.data, children: null });
        updateGroupType(response.data?.data);
      }

      if (response.status === 200) {
        setData(documentRes);
        console.log(documentRes);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      showToast(
        'error',
        ex?.data?.message || ex.message || 'Something went wrong'
      );
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getDocumentDetail = async (
    groupId: string,
    docId: string
  ): Promise<Document[] | null> => {
    try {
      setLoading(true);
      // check if it's group document or a folder documents
      const documentListEndPoint = APIs.DOCUMENT.DETAIL(docId);
      const response = await apiClient({
        ...documentListEndPoint,
      });

      const documentRes = response.data?.data;
      setGroupType('own');
      setOtherDetails({ group: response.data?.data?.group });
      if (response.data?.data) {
        const otherDetails = {
          ...response.data?.data,
        };
        setOtherDetails(otherDetails);
        updateGroupType(otherDetails);
        setDocument(otherDetails);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      showToast(
        'error',
        ex?.data?.message || ex.message || 'Something went wrong'
      );
      throw new Error(ex);
      return null;
      // throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getDocumentVersions = async (
    docId: string
  ): Promise<DocumentVersion[] | null> => {
    try {
      setLoading(true);
      const documentVersionEndPoint = APIs.DOCUMENT.VERSION(docId);
      const response = await apiClient(documentVersionEndPoint);

      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      const docVersionList = response.data?.data;
      console.log('docvserlist', docVersionList);

      setDocumentVersion(docVersionList);

      return docVersionList;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      showToast(
        'error',
        ex?.data?.message || ex.message || 'Something went wrong'
      );
      throw new Error(ex);
    } finally {
      setLoading(false);
    }
  };

  const downloadFolder = async (
    documentId: string,
    requestPayload: any
  ): Promise<void> => {
    try {
      // check if it's group document or a folder documents
      const documentDownloadEndPoint = getDocumentViewUrl(documentId);
      // open download link in new tab

      window.open(documentDownloadEndPoint, '_blank');
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const documentDownload = async (documentId: string): Promise<void> => {
    try {
      setLoading(true); // Show loading indicator

      // Fetch the pre-signed URL from the backend
      const response = await apiClient({
        ...APIs.DOCUMENT.DOCUMENT_DOWNLOAD(documentId),
      });

      if (response.status === 200 && response.data?.data) {
        const preSignedUrl = response.data.data;

        // Open the pre-signed URL in a new tab for download
        window.open(preSignedUrl, '_blank');
      } else {
        throw new Error('Failed to fetch pre-signed URL');
      }
    } catch (error: any) {
      console.error('Error downloading the document:', error);
      setError(error.message); // Handle and display error
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const downloadDocumentVersion = async (versionId: string) => {
    try {
      // Fetch the presigned URL for the document version
      const response = await apiClient({
        ...APIs.DOCUMENT.DOCUMENT_VERSION_DOWNLOAD(versionId), // Make sure you have the right endpoint for getting the presigned URL
      });

      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      const presignedUrl = response.data?.data;
      console.log('Presigned URL:', presignedUrl);
      // Open the document download link in a new tab
      window.open(presignedUrl, '_blank');
    } catch (ex: any) {
      console.error('Error downloading document version:', ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    }
  };

  const shareDocument = async (
    documentId: string,
    requestPayload: any,
    action: 'SHARE' | 'SHARE_PATCH'
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...(action === 'SHARE'
          ? APIs.DOCUMENT.SHARE(documentId)
          : APIs.DOCUMENT.SHARE_UPDATE(documentId)),
        data: requestPayload,
      });

      const documentRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const getSharedDocumentUsers = async (
    documentId: string
  ): Promise<SharedUser[]> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.GET_SHARED_USERS(documentId),
      });

      const documentRes = response.data?.data?.map((sharedUser: any) => {
        return {
          ...sharedUser?.user,
          ...sharedUser,
          permission: sharedUser.action,
          name: getUserFullName(sharedUser.user),
        };
      });
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const createDocumentVersion = async (
    documentId: string,
    requestPayload: any
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.DOCUMENT.CREATE_VERSION(documentId),
        data: requestPayload,
      });
      const documentRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      // showToast('success', `Document updated successfully`);
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  const uploadDocumentToS3 = async (
    docArrayBuffer: File,
    signedUrl: string
  ): Promise<Response> => {
    try {
      setLoading(true);
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: docArrayBuffer,
        headers: {
          'Content-Type': 'application/octet-stream', // Ensure correct content type
          'x-amz-server-side-encryption': 'aws:kms',
        },
      });
      const documentRes = response;
      if (response.status === 400) {
        throw new Error('Error in uploading document');
      }
      // showToast('success', `Document version uploaded successfully`);
      return documentRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      showToast('error', ex?.data?.message || 'Something went wrong');
      throw new Error(ex.data.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    error,
    createDocument,
    checkFileNames,
    updateDocument,
    deleteDocument,
    getDocumentList,
    downloadFolder,
    documentDownload,
    downloadDocumentVersion,
    getDocumentDetail,
    getDocumentVersions,
    shareDocument,
    getSharedDocumentUsers,
    createDocumentVersion,
    uploadDocumentToS3,
    deleteDocumentVersion,
  };
};

export default useDocumentService;
