export enum JobStatus {
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum DocSharePermissions {
  VIEWER = 'Viewer',
  EDITOR = 'Editor',
  OWNER = 'OWNER',
  NONE = 'None',
}

export enum DocShareAccessType {
  RESTRICTED = 'RESTRICTED',
  PUBLIC = 'PUBLIC',
}

export enum ActionPermissions {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SHARE = 'SHARE',
  CREATE = 'ADD',
  DOWNLOAD = 'DOWNLOAD',
}

// export enum type of permissions
export type PermissionTypes = 'Viewer' | 'Editor' | 'None';

// permissions.ts
export type Permission =
  | 'VIEW'
  | 'ADD'
  | 'EDIT'
  | 'DELETE'
  | 'SHARE'
  | 'DOWNLOAD';

export enum DocType {
  All = 'all',
  Folder = 'folders',
  Document = 'documents',
}

// Enum for Preview Extensions
export enum PreviewExtensions {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  XLSM = 'xlsm',
  TIF = 'tif',
}

// Enum for Non-Preview Extensions
export enum NonPreviewExtensions {
  ZIP = 'zip',
  MSG = 'msg',
  EXE = 'exe',
  JPG = 'jpg',
  JPEG = 'jpeg',
}

// Type for all extensions
export type FileExtension =
  | keyof typeof PreviewExtensions
  | keyof typeof NonPreviewExtensions;
