import React, { useState } from 'react';
import Modal from 'packages/web/src/components/Modal'; // Custom Modal component
import { Button } from 'design-web'; // Button from design-web
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import useAccessControl from 'shared/hooks/useAccessControl';
import { ActionPermissions } from 'shared/types/types';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import { Document } from 'shared/contexts/DocumentContext';

interface CustomFile {
  name: string;
  [key: string]: any;
}

interface AcceptFileModalProps {
  isOpen: boolean;
  acceptFiles: CustomFile[];
  onResolve: (resolvedFiles: CustomFile[]) => void;
  onClose: () => void;
  closeResolve: () => void;
  duplicateFileResponse: { data: Document[] } | null; // Updated to match the expected structure
}

const AcceptFileModal: React.FC<AcceptFileModalProps> = ({
  isOpen,
  acceptFiles,
  onResolve,
  onClose,
  closeResolve,
  duplicateFileResponse,
}) => {
  const canEdit = useAccessControl(ActionPermissions.EDIT);
  const { userInfo } = useAuthContext();
  const [resolvedFiles, setResolvedFiles] = useState<CustomFile[]>(
    acceptFiles.map(file => {
      // Find the corresponding file in duplicateFileResponse based on file name
      const duplicateFile = duplicateFileResponse?.data.find(
        duplicateFile => duplicateFile.name === file.name
      );

      // Determine if the current user is the owner or has edit permissions
      const canReplace =
        canEdit ||
        userInfo?.isSuperAdmin ||
        duplicateFile?.userId === userInfo?.id;

      return {
        ...file,
        action: canReplace ? 'replace' : 'rename', // Default to 'replace' if user has permission, else 'rename'
        renameError: '',
        name: file.name,
      };
    })
  );
  const { groupId, folderId } = useParams<any>();
  const { checkFileNames } = useDocumentService(); // API for checking file names
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  const getExtension = (fileName: string) => fileName.split('.').pop(); // Extract extension
  const getBaseName = (fileName: string) =>
    fileName.split('.').slice(0, -1).join('.'); // Extract base name

  // Function to check if file names already exist
  const checkFileExists = async (fileNames: string[]) => {
    const response = await checkFileNames(fileNames, groupId ?? '', folderId);
    return response?.data ?? []; // Return an array of existing file names
  };

  const handleActionChange = (index: number, action: string) => {
    const updatedFiles = [...resolvedFiles];
    updatedFiles[index].action = action;

    if (action !== 'rename') {
      // Clear name and error when switching away from rename
      updatedFiles[index].renameError = '';
      updatedFiles[index].name = acceptFiles[index].name; // Reset the name to the original
    }

    setResolvedFiles(updatedFiles);
  };

  const handleRenameChange = (index: number, newName: string) => {
    const updatedFiles = [...resolvedFiles];
    const extension = getExtension(acceptFiles[index].name); // Get original extension
    updatedFiles[index].name = `${newName}.${extension}`; // Preserve extension
    setResolvedFiles(updatedFiles);
  };

  const handleRenameBlur = (index: number) => {
    validateRenameField(index);
  };

  // Validation for individual rename fields (Yup validation)
  const validateRenameField = (index: number) => {
    const updatedFiles = [...resolvedFiles];
    const baseName = getBaseName(updatedFiles[index].name).trim(); // Get base name without extension
    const renameSchema = Yup.string()
      .trim()
      .required('File name is required') // Ensure non-empty base name
      .test(
        'has-letter',
        'File name must have at least one character before the extension',
        value => {
          return !!value; // Ensures that value is not empty
        }
      );

    renameSchema
      .validate(baseName) // Validate only the base name
      .then(() => {
        updatedFiles[index].renameError = ''; // Clear error on valid name
      })
      .catch(err => {
        updatedFiles[index].renameError = err.message; // Set error on invalid name
      });

    setResolvedFiles(updatedFiles); // Update state with validation results
  };

  // Handle validation and submission on Resolve click (Yup + file existence)
  const handleResolve = async () => {
    setIsSubmitInProgress(true);
    const updatedFiles = [...resolvedFiles];
    let hasErrors = false;

    // Collect all the file names that are being renamed
    const renameFileNames = updatedFiles
      .filter(file => file.action === 'rename')
      .map(file => file.name);

    renameFileNames.forEach((file, index) => {
      const baseName = getBaseName(file).trim(); // Extract base name for validation
      const extension = getExtension(file); // Extract extension for validation
      renameFileNames[index] = `${baseName}.${extension}`;
    });

    let existingNames: string[] = [];
    if (renameFileNames.length > 0) {
      const existingFiles = await checkFileExists(renameFileNames);
      existingNames = existingFiles.map((file: any) => file.name);
    }

    // Perform Yup validation for required fields
    updatedFiles.forEach((file, index) => {
      if (file.action === 'rename') {
        const baseName = getBaseName(file.name).trim(); // Extract base name for validation
        const extension = getExtension(file.name); // Extract extension for validation
        const trimmedFileName = `${baseName}.${extension}`;

        const schemaValidation = Yup.string()
          .trim()
          .required('File name is required') // Base name required
          .test(
            'has-letter',
            'File name must have at least one character before the extension',
            value => {
              return !!value; // Validate if base name has at least one character
            }
          );

        // Validate for duplicate file names
        if (existingNames.includes(trimmedFileName)) {
          updatedFiles[index].renameError = 'File name already exists';
          hasErrors = true;
        } else {
          try {
            // Yup validation for required field
            schemaValidation.validateSync(baseName);
            updatedFiles[index].renameError = ''; // Clear error if valid
          } catch (err) {
            updatedFiles[index].renameError = (
              err as Yup.ValidationError
            ).message; // Set error message
            hasErrors = true;
          }
        }
      }
    });

    // If any fields fail the Yup validation, return early
    if (hasErrors) {
      setResolvedFiles(updatedFiles);
      setIsSubmitInProgress(false);
      return;
    }

    // Proceed with submission if no errors
    onResolve(updatedFiles);
    closeResolve();
    setIsSubmitInProgress(false);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Resolve File Actions"
        loading={false}
        className="max-w-2xl"
      >
        {<DataSaveLoader visible={isSubmitInProgress} />}
        <p className="text-gray-900 text-sm sm:text-base font-normal">
          {`${resolvedFiles.length > 1 ? 'These files' : acceptFiles[0].name}`}{' '}
          already exist in this location. Do you want to replace the existing{' '}
          {`${resolvedFiles.length > 1 ? 'files' : 'file'}`} with a new version,
          or rename the new file? you can't change the file extension (e.g.,
          .docx, .doc, .pdf, .xls, .xlsx, .xlsm, .jpg, .zip, .tif, .msg, .exe)
          while renaming.
        </p>
        <ul className="my-6 space-y-4">
          {resolvedFiles.map((file, index) => {
            const duplicateFile = duplicateFileResponse?.data.find(
              duplicateFile => duplicateFile.name === file.name
            );

            // Check if the current user is the owner of the duplicate file
            const isOwner = duplicateFile?.userId === userInfo?.id;
            return (
              <li key={index} className="p-4 bg-gray-100 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <span
                    className="font-medium text-gray-900 text-semiBase sm:text-lg break-words max-w-lg sm:max-w-3xl"
                    style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                  >
                    {/* {file.name || file.path} */}
                    {acceptFiles[index].name}
                  </span>
                </div>
                <div className="flex flex-row space-x-2 sm:space-x-12">
                  {(canEdit || userInfo?.isSuperAdmin || isOwner) && (
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name={`action-${index}`}
                        value="replace"
                        checked={resolvedFiles[index].action === 'replace'}
                        onChange={() => handleActionChange(index, 'replace')}
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <span className="sm:ml-2 text-gray-700 text-sm sm:text-base">
                        Replace existing file
                      </span>
                    </label>
                  )}

                  <label className="flex items-center justify-end">
                    <input
                      type="radio"
                      name={`action-${index}`}
                      value="rename"
                      checked={resolvedFiles[index].action === 'rename'}
                      onChange={() => handleActionChange(index, 'rename')}
                      className="form-radio h-4 w-4 text-blue-600"
                    />
                    <span className="sm:ml-2 text-gray-700 text-sm sm:text-base">
                      Rename file
                    </span>
                  </label>
                </div>
                {resolvedFiles[index].action === 'rename' && (
                  <div className="mt-2 sm:mt-4">
                    <input
                      type="text"
                      value={getBaseName(file.name)}
                      onChange={e => handleRenameChange(index, e.target.value)}
                      onBlur={() => handleRenameBlur(index)}
                      placeholder="Enter new file name"
                      maxLength={100}
                      className={`mt-1 p-2 text-xs sm:text-base font-medium sm:font-bold text-gray-500 rounded-md w-full sm:w-3/4 border ${
                        resolvedFiles[index].renameError
                          ? 'border-brandRed'
                          : 'border-gray-300'
                      }`}
                    />
                    {/* Add the Max 100 characters message below the input */}
                    <div className="text-gray-500 font-bold text-sm mt-1 ml-1">
                      Max: 100 char
                    </div>
                    {resolvedFiles[index].renameError && (
                      <div className="text-brandRed font-inter text-semiBase sm:text-base font-medium leading-[19.5px] text-left mt-1">
                        {resolvedFiles[index].renameError}
                      </div>
                    )}
                  </div>
                )}
              </li>
            );
          })}
        </ul>

        <div className="flex justify-end space-x-4">
          <Button
            onClick={handleResolve}
            className="py-1 sm:py-2 w-40 mt-4 mb-4 text-white whitespace-nowrap rounded-xl bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 text-base sm:text-xl font-normal leading-[30px] text-center"
          >
            Resolve
          </Button>
          <Button
            onClick={onClose}
            className="py-1 sm:py-2 w-40 mt-4 mb-4 text-white whitespace-nowrap rounded-xl bg-danger hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 text-base sm:text-xl font-normal leading-[30px] text-center"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AcceptFileModal;
