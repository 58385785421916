import React, { useEffect, useRef, useState } from 'react';

import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';

interface MainState {
  currentDocument: {
    uri: string;
  } | null;
}

interface CustomFileRenderProps {
  mainState: MainState;
}

interface CustomFileRender extends React.FC<CustomFileRenderProps> {
  fileTypes: string[];
  weight: number;
  fileLoader: (props: {
    documentURI: string;
    signal: AbortSignal;
    fileLoaderComplete: () => void;
  }) => void;
}

const CustomFileRender: CustomFileRender = ({
  mainState: { currentDocument },
}) => {
  const [seed, setSeed] = useState(1);
  const [docUrl, setDocUrl] = useState('');
  const isIframeLoadedRef = useRef(false);
  const timeIntervalRef = useRef<any>(null);

  useEffect(() => {
    if (!currentDocument) return;
    setTimer();

    //if (!docUrl)
    setTimeout(() => {
      setDocumentUrl();
    }, 100);

    return () => clearInterval(timeIntervalRef.current);
  }, [currentDocument, seed]);

  const setTimer = () => {
    let retryCount = 0;
    clearInterval(timeIntervalRef.current);
    timeIntervalRef.current = setInterval(() => {
      console.log(isIframeLoadedRef.current, 'timeout');
      if (!isIframeLoadedRef.current) {
        if (retryCount < 10) {
          setDocumentUrl();
          retryCount++;
        } else {
          clearInterval(timeIntervalRef.current);
          console.log('Max retries reached');
        }
      } else {
        clearInterval(timeIntervalRef.current);
      }
    }, 3000);
  };

  const setDocumentUrl = () => {
    if (!currentDocument) return;
    const googleDocsViewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
      currentDocument.uri + `&cacheBust=${Date.now()}`
    )}`;
    setDocUrl(googleDocsViewerUrl);
  };

  const handleIframeLoad = (data: any) => {
    isIframeLoadedRef.current = true;
    console.log(data.target.children, 'test');
  };

  if (!currentDocument || !docUrl) return null;

  return (
    <div
      style={{ width: '100%', minHeight: '75vh' }}
      id="my-doc-renderer"
      className="relative"
    >
      <iframe
        className={'doc absolute z-[1000]'}
        width="100%"
        height="80vh"
        onLoad={handleIframeLoad}
        src={docUrl}
        frameBorder="0"
        title="doc view"
        id="doc-viewer"
      ></iframe>
      {/* // place a fileLoader */}
      <div className="flex justify-center items-center py-4">
        {<DataSaveLoader visible={true} />}
      </div>
    </div>
  );
};

CustomFileRender.fileLoader = ({ documentURI, signal, fileLoaderComplete }) => {
  console.log(documentURI, signal);
  fileLoaderComplete();
};

CustomFileRender.fileTypes = [
  'PDF',
  'pdf',
  'application/pdf',
  'xls',
  'application/vnd.ms-excel',
  'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'ppt',
  'application/vnd.ms-powerpoint',
  'pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'doc',
  'application/msword',
  'docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'xlsm',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'jpg',
  'image/jpeg',
  'zip',
  'application/zip',
  'tif',
  'image/tiff',
  'msg',
  'application/vnd.ms-outlook',
  'exe',
  'application/vnd.microsoft.portable-executable',
];

CustomFileRender.weight = 1;

export default CustomFileRender;
