import React from 'react';

interface Option {
  value: string;
  label: string;
}

interface CommonSelectProps {
  options: Option[]; // Array of options with value and label
  value: string; // Selected value
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void; // Change handler
  className?: string; // Optional class for the <select> element
  wrapperClass?: string; // Optional class for the wrapper <div>
}

const CommonSelect: React.FC<CommonSelectProps> = ({
  options = [],
  value = '',
  onChange,
  className = '',
  wrapperClass = '',
}) => {
  return (
    <div className={`relative inline-block ${wrapperClass}`}>
      <select
        className={`appearance-none bg-white border border-gray-300 text-black text-base font-medium py-2 pr-8 pl-2 rounded-full md:rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full md:w-auto ${className}`}
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-2 flex items-center pointer-events-none pl-6">
        <svg
          className="w-6 h-6 text-blue-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
  );
};

export default CommonSelect;
