// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

import { createContext, useEffect, useReducer, useRef } from 'react';
// import { FileUploadContextType } from 'types/fileUpload';
import fileUploadReducer, {
  initialState,
} from './file-upload-reducer/fileUpload';
import apiService, {
  cancelRequest,
  getAbortController,
} from '../utils/apiService';
import {
  ADD_FILE_IN_QUEUE,
  DELETE_FILE_IN_PROGRESS,
  RESET,
  START_UPLOAD,
  UPDATE_FILES_STORE,
  UPDATE_FILE_STATE,
  SET_DUPLICATE_FILE_RESPONSE,
} from './file-upload-reducer/actions';
import { useParams } from 'react-router-dom';
import { JobStatus } from '../types/types';

import APIs from '../config/apis';
import { showToast } from 'design-web';
import { CustomFile } from '../types/dropzone';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import { MediaDocumentContentTypeEnum } from '../types/media';

const FileUploadContext = createContext<any | null>(null);

export const FileUploadProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(fileUploadReducer, initialState);
  // Get the jobId param from the URL.
  const { groupId, folderId } = useParams();
  const activeFilesRef = useRef(state.files);
  const duplicateFilesRef = useRef(state.duplicateFiles);
  const abortControllerRef = useRef(getAbortController());
  const { checkFileNames, createDocumentVersion } = useDocumentService();

  useEffect(() => {
    if (state.isUploading) {
      activeFilesRef.current = state.files || [];
      startFileUploadProcess();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isUploading]);

  useEffect(() => {
    console.log(state.files, 'state files');
    activeFilesRef.current = state.files || [];
    duplicateFilesRef.current = state.duplicateFiles || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.files, state.duplicateFiles]);

  const addFilesToStore = (files: CustomFile[]) => {
    dispatch({
      type: UPDATE_FILES_STORE,
      payload: {
        files: [...state.files, ...files],
      },
    });
  };

  const startFileUploadProcess = async (localState = state) => {
    let file;
    try {
      // start file uploading.
      if (activeFilesRef.current?.length) {
        const files = [...activeFilesRef.current];
        file = files.shift();
        dispatch({
          type: UPDATE_FILES_STORE,
          payload: {
            files: [...files],
          },
        });
        dispatch({
          type: ADD_FILE_IN_QUEUE,
          payload: {
            file,
          },
        });
        const fileExtension = file.name
          .split('.')
          .pop() as keyof typeof MediaDocumentContentTypeEnum; // Get the last part of the string after the last dot
        const fileName = file.name;
        const { data } = await getFileUploadUrl({
          fileName: fileName,
          contentType: file.type || MediaDocumentContentTypeEnum[fileExtension],
          groupId,
          parentId: folderId || undefined,
        });

        // check if we are getting signed url and file is still in progress and it's not deleted
        if (data?.signedUrl && state.filesInProgress[fileName]) {
          const fileStatePayload = {
            fileName: file.name,
            filePath: file.filePath,
            signedUrl: data?.signedUrl,
            documentUUID: data?.documentUUID,
            versionUUID: data?.versionUUID,
            groupId: groupId,
            parentId: folderId || undefined,
            s3FileName: fileName,
          };

          const fileStatePayloadForExistingFile = {
            ...fileStatePayload,
            isFolder: false,
            name: file.name,
          };

          dispatch({
            type: UPDATE_FILE_STATE,
            payload: fileStatePayload,
          });
          await uploadFile(file, data.signedUrl);
          // dispatch action to move file from inprogress state to complete state
          dispatch({
            type: UPDATE_FILE_STATE,
            payload: {
              fileName: file.name,
              filePath: file.filePath,
              status: JobStatus.COMPLETED,
            },
          });

          const existingFile = duplicateFilesRef.current;
          const matchingFiles = existingFile?.data.find(
            (duplicateFile: any) => duplicateFile.name === fileName
          );
          // If the file exists, create a new version; otherwise, create a new document entry
          if (file.action && file.action === 'replace' && matchingFiles) {
            await createDocumentVersion(matchingFiles.id, {
              ...fileStatePayloadForExistingFile,
              versionUUID: data.versionUUID, // Use the new version UUID from the response
            });

            showToast('success', `${file.name} updated as a new version`);
          } else {
            // If the file is unique, create a new document entry
            await CreateDocumentEntry(file, fileStatePayload);
            showToast('success', `${file.name} is uploaded successfully`);
          }
          //  addFileToJob(file, data.filePath)
          // once a file is successfull
          startFileUploadProcess();
        }
      } else {
        // all files uploaded then update isUploading flag to false
        dispatch({
          type: UPDATE_FILES_STORE,
          payload: {
            isUploading: false,
            jobStatus: JobStatus.COMPLETED,
          },
        });
      }
    } catch (err: any) {
      handleResponseError(file, err);
      // start uploading next file
      if (err.code !== 'ERR_CANCELED') {
        startFileUploadProcess();
      }
    }
  };

  const stopFileUploadProcess = () => {
    cancelRequest(abortControllerRef.current);
  };

  const getFileUploadUrl = async (payload: any) => {
    return apiService.post(APIs.DOCUMENT.UPLOAD().url, payload);
  };

  const uploadFile = async (file: File, signedUrl: string) => {
    abortControllerRef.current = getAbortController();
    return apiService.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
        'x-amz-server-side-encryption': 'aws:kms',
      },
      signal: abortControllerRef.current.signal,
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent?.total) {
          const percentCompleted = Math.round(
            (progressEvent?.loaded * 100) / progressEvent?.total
          );
          dispatch({
            type: UPDATE_FILE_STATE,
            payload: {
              fileName: file.name,
              progress: percentCompleted,
              status: JobStatus.RUNNING,
            },
          });
          console.log(percentCompleted);
        }
      },
    });
  };

  const startUpload = (files: File[]) => {
    dispatch({
      type: START_UPLOAD,
      payload: {
        files,
      },
    });
  };

  const duplicateFilesRes = (files: File[]) => {
    dispatch({
      type: SET_DUPLICATE_FILE_RESPONSE,
      payload: {
        files,
      },
    });
  };

  const restartUploadProcess = async (fileState: any) => {
    const { file } = fileState;
    try {
      dispatch({
        type: UPDATE_FILE_STATE,
        payload: {
          fileName: file.name,
          status: JobStatus.RUNNING,
          progress: 0,
        },
      });

      // check if signedUrl is available then start uploading
      if (fileState?.signedUrl) {
        await uploadFile(file, fileState?.signedUrl);
        //  addFileToJob(file, fileState.filePath)
        return;
      }

      const { data } = await getFileUploadUrl({
        fileName: file.name,
        contentType: file.type,
      });

      if (data?.signedUrl) {
        dispatch({
          type: UPDATE_FILE_STATE,
          payload: {
            fileName: file.name,
            signedUrl: data?.signedUrl,
          },
        });
        await uploadFile(file, data.signedUrl);
        await CreateDocumentEntry(file, state.filesInProgress[file.name]);
        showToast('success', `${file.name} is uploaded successfully`);
        // addFileToJob(file, data.filePath)
      }
    } catch (err: any) {
      console.log(err);
      handleResponseError(file, err);
    }
  };

  const CreateDocumentEntry = async (file: File, fileStatePayload: any) => {
    try {
      const { data } = await apiService.post(APIs.DOCUMENT.CREATE.url, {
        name: fileStatePayload.s3FileName,
        fileName: fileStatePayload.s3FileName,
        type: file.type,
        size: file.size,
        groupId: groupId,
        parentId: folderId,
        isFolder: false,
        documentUUID: fileStatePayload.documentUUID,
        versionUUID: fileStatePayload.versionUUID,
      });
    } catch (err: any) {
      console.log(err);
      throw new Error(err);
    }
  };

  const onRemoveFile = (file: File) => {
    if (!state.files) {
      return;
    }

    // check if file status is running then stop file upload progress
    if (state.filesInProgress[file.name]?.status === JobStatus.RUNNING) {
      stopFileUploadProcess();
    }
    const filesInProgress = { ...state.filesInProgress };
    const FileInProgress = state.filesInProgress[file.name];
    FileInProgress && delete filesInProgress[file.name];

    if (FileInProgress) {
      dispatch({
        type: DELETE_FILE_IN_PROGRESS,
        payload: FileInProgress,
      });
    }

    const filteredItems =
      (state.files && state.files.filter((_file: File) => _file !== file)) ||
      [];

    const upldatedState = {
      files: [...filteredItems],
    };
    dispatch({
      type: UPDATE_FILES_STORE,
      payload: upldatedState,
    });

    if (FileInProgress) {
      // start next file in progress
      startFileUploadProcess({
        ...upldatedState,
        filesInProgress: filesInProgress,
      });
    }
  };

  // const addFileToJob = async (file:File, path:string) => {
  //   try {
  //     const { data } = await apiService.post(`jobs/${jobId}/files`, {"name": file.name,
  //     "path": path,
  //     "type": file.type,
  //     "size": file.size});
  //     return data;
  //   } catch(err) {
  //     console.log(err);
  //   }
  // }

  // const addJobToQueue = async () => {
  //   try {
  //     const { data } = await apiService.post(`jobs/${jobId}/add_to_queue`);
  //     return data;
  //   } catch(err) {
  //     console.log(err);
  //   }
  // }

  const handleResponseError = (file: File, err: any) => {
    //   if(activeFilesRef.current.length === 0) {
    //   dispatch({
    //     type: UPDATE_FILES_STORE,
    //     payload: {
    //       isUploading: false,
    //       jobStatus: JobStatus.COMPLETED,
    //     },
    //   });
    // }
    if (err.code === 'ERR_CANCELED') {
      console.log(err.code);
    } else {
      showToast('error', `Error in uploading ${file.name} file`);
      dispatch({
        type: UPDATE_FILE_STATE,
        payload: {
          fileName: file.name,
          status: 'error',
          errorMsg: err?.error?.message || 'Something Went Wrong',
        },
      });
    }
  };

  const resetFileContext = () => {
    dispatch({
      type: RESET,
    });
  };

  return (
    <FileUploadContext.Provider
      value={{
        ...state,
        startUpload,
        restartUploadProcess,
        onRemoveFile,
        resetFileContext,
        stopFileUploadProcess,
        addFilesToStore,
        getFileUploadUrl,
        duplicateFilesRes,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

export default FileUploadContext;
